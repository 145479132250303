<template>
	<v-layout column>
		<v-progress-linear v-if="loading" color="primary" indeterminate style="margin: 0" />
		<v-flex pa-3>
			<v-layout column>
				<w-text-info :text="$t('quickfolders.automation_form_help')" />
				<v-subheader v-text="$t('quickfolders.send_documents_to', { quickfolder: value.name }) + ' :'" />
				<v-flex v-if="!loading && value.id" scroll-y>
					<v-layout v-if="applications && applications.length > 0" row wrap>
						<w-switch
							v-for="application in applications"
							:key="application.id + '-' + value.id"
							:value="usedApplications.includes(application.id)"
							:label="application.title"
							@input="onChange($event, application)"
						/>
					</v-layout>
					<v-layout v-else align-center justify-center row>
						<v-icon color="error">warning</v-icon>
						<v-flex v-t="'quickfolders.no_application_available'" ml-3 />
					</v-layout>
				</v-flex>
			</v-layout>
		</v-flex>
	</v-layout>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'QuickfolderApplicationForm',
	mixins: [QuickFoldersModuleGuard],
	props: {
		value: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			applications: [],
			loading: false,
			usedApplications: []
		}
	},
	watch: {
		vendorId: {
			handler: function (val) {
				if (val) {
					this.loading = true
					this.applications.clear()
					const vendorId = this.vendorId
					return this.service
						.getApplications(vendorId, { is_automation: true })
						.then(applications => {
							this.applications = applications
						})
						.finally(() => {
							this.loading = false
						})
				}
			},
			immediate: true
		},
		value: {
			handler: function (val) {
				this.usedApplications.clear()
				if (val && val.applications) {
					val.applications.forEach(app => {
						if (isNaN(app)) {
							this.usedApplications.push(app.id)
						} else {
							this.usedApplications.push(app)
						}
					})
				}
			},
			immediate: true
		}
	},
	methods: {
		emitInput: function (newValue) {
			this.$emit('input', newValue)
		},
		onChange: function (isActive, app) {
			const quickfolderId = this.value.id
			const applications = [...this.value.applications]

			return this.service.changeQuickfolderApplication(this.vendorId, quickfolderId, app.id, isActive).then(() => {
				if (isActive) {
					applications.push(app.id)
				} else {
					const index = applications.findIndex(appId => appId == app.id)
					if (index) {
						applications.splice(index, 1)
					}
				}

				if (this.value.id == quickfolderId) {
					this.usedApplications.splice(0, this.usedApplications.length)
					applications.forEach(appId => {
						this.usedApplications.push(appId)
					})
				}
				this.emitInput({ id: quickfolderId, applications })
			})
		}
	}
}
</script>
